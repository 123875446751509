import React from 'react';

const Footer = () => {
  return (
    <footer className='d-flex align-items-center justify-content-center animated fadeInUp delay-16s'>
      <div className='social-media text-center'>
        <a
          href='https://www.linkedin.com/in/mohamed-azouaoui/'
          target='_blank'
          rel='noopener noreferrer'
          className='mr-3 ml-4'
        >
          <i className='fab fa-linkedin' />
        </a>
        <a
          href='https://twitter.com/azouaoui_med'
          target='_blank'
          rel='noopener noreferrer'
          className='mr-3 ml-4'
        >
          <i className='fab fa-twitter' />
        </a>
        <a
          href='https://www.instagram.com/azouaoui_med/'
          target='_blank'
          rel='noopener noreferrer'
          className='mr-3 ml-4'
        >
          <i className='fab fa-instagram' />
        </a>
        <a
          href='https://stackoverflow.com/users/3844188'
          target='_blank'
          rel='noopener noreferrer'
          className='mr-3 ml-4'
        >
          <i className='fab fa-stack-overflow' />
        </a>
        <a
          href='https://github.com/azouaoui-med'
          target='_blank'
          rel='noopener noreferrer'
          className='mr-3 ml-4'
        >
          <i className='fab fa-github' />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
