import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-md'>
      <div className='container'>
        <div className='navbar-collapse'>
          <ul className='navbar-nav ml-auto mr-auto'>
            <li className='nav-item'>
              <NavLink className='nav-link' exact to='/'>
                <i className='fas fa-chalkboard' />
                <span>Home</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/about'>
                <i className='fas fa-address-card' />
                <span>About</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/skills'>
                <i className='fas fa-code' />
                <span>Skills</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/portfolio'>
                <i className='fas fa-gem' />
                <span>Portfolio</span>
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/contact'>
                <i className='fas fa-paper-plane' />
                <span>Contact</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
