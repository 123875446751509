import React from 'react';
import { NavLink } from 'react-router-dom';

import SectionWrapper from '../../../HOC/SectionWrapper/SectionWrapper';
import Footer from '../../UI/Footer/Footer';

const Home = () => {
  return (
    <SectionWrapper id='home'>
      <div className='home-content'>
        {/* <p>Hello,</p> */}
        <h1 className='home-title animated fadeInDown delay-02s'>
          Mohamed Azouaoui
        </h1>
        <h2 className='home-subtitle animated fadeIn delay-05s'>
          I am a software engineer and a full stack web developer
        </h2>
        <NavLink
          className='btn btn-custom animated fadeInUp delay-1s'
          to='/contact'
        >
          {/* <i className='fas fa-paper-plane mr-3' /> */}
          <span>CONTACT ME</span>
        </NavLink>
      </div>
      <Footer />
    </SectionWrapper>
  );
};

export default Home;
