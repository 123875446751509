import React from 'react';

const SectionWrapper = props => {
  return (
    <section id={props.id} className='d-flex align-items-center'>
      <div className='innerSection'>{props.children}</div>
    </section>
  );
};

export default SectionWrapper;
