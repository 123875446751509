import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import SectionWrapper from '../../../HOC/SectionWrapper/SectionWrapper';

const Skills = () => {
  const [skillValues, setSkillValues] = useState({
    frontend: 0,
    backend: 0,
    ResponsiveDesign: 0,
    gisProgramming: 0
  });
  useEffect(() => {
    setTimeout(() => {
      setSkillValues({
        frontend: 85,
        backend: 75,
        ResponsiveDesign: 90,
        gisProgramming: 70
      });
    }, 800);
  }, []);

  return (
    <SectionWrapper id='skills'>
      <div className='container'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title animated fadeInUp delay-02s'>Skills</h3>
          <h6 className='section-subtitle mr-auto ml-auto animated fadeInUp delay-05s'>
            What i'm best at
          </h6>
        </div>
        <div className='section-content'>
          <div className='row animated fadeIn delay-08s'>
            <div className='col-sm-6 col-md-3 mb-5 '>
              <div className='circularProgressContainer mr-auto ml-auto mb-4'>
                <CircularProgressbar
                  value={skillValues.frontend}
                  text={`${skillValues.frontend}%`}
                />
              </div>
              <div className='skill description'>
                <h6>Front End</h6>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 mb-5'>
              <div className='circularProgressContainer mr-auto ml-auto mb-4'>
                <CircularProgressbar
                  value={skillValues.backend}
                  text={`${skillValues.backend}%`}
                />
              </div>
              <div className='skill description'>
                <h6>Back End</h6>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 mb-5'>
              <div className='circularProgressContainer mr-auto ml-auto mb-4'>
                <CircularProgressbar
                  value={skillValues.ResponsiveDesign}
                  text={`${skillValues.ResponsiveDesign}%`}
                />
              </div>
              <div className='skill description'>
                <h6>Responsive Design</h6>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 mb-5'>
              <div className='circularProgressContainer mr-auto ml-auto mb-4'>
                <CircularProgressbar
                  value={skillValues.gisProgramming}
                  text={`${skillValues.gisProgramming}%`}
                />
              </div>
              <div className='skill description'>
                <h6>GIS Programming</h6>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 mb-4'>
              <h6 className='text-uppercase font-weight-bold animated fadeInUp delay-12s'>
                Technical Skills
              </h6>
            </div>
            <div className='col-md-4 animated fadeIn delay-14s '>
              <p>Javascript / ES6, ES7, ES8,... </p>
              <p>Typescript</p>
              <p>React / Redux</p>
              <p>Angular</p>
              <p>JQuery</p>
              <p>HTML 5 / CSS 3 / SCSS</p>
              <p>Bootstrap / Material UI</p>
            </div>
            <div className='col-md-4 animated fadeIn delay-14s'>
              <p>Node Js / Express Js</p>
              <p>Python</p>
              <p>Firebase</p>
              <p>MongoDB</p>
              <p>PostgreSQL</p>
              <p>Jest</p>
              <p>Docker</p>
            </div>
            <div className='col-md-4 animated fadeIn delay-14s'>
              <p>Arcgis / Arcgis Js Api</p>
              <p>Qgis / PyQgis</p>
              <p>Git / Github</p>
              <p>Webpack</p>
              <p>Gulp / Grunt</p>
              <p>Babel</p>
              <p>Scrum / Git flow</p>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Skills;
