import React from 'react';

import SectionWrapper from '../../../HOC/SectionWrapper/SectionWrapper';

const Contact = () => {
  return (
    <SectionWrapper id='contact'>
      <div className='container'>
        <div className='section-header pt-5 pb-4 text-center'>
          <h3 className='section-title animated fadeInUp delay-02s'>
            Contact Me
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto animated fadeInUp delay-05s'>
            If you have any questions, don't hesitate to contact me
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-9 mr-auto ml-auto'>
              <div className='row contact-info d-flex justify-content-center mb-3 animated fadeInUp delay-08s'>
                <div className='col-md-4 col-lg-4'>
                  <div>
                    <i className='fas fa-phone-alt mr-2' />
                    <span>+213 (0) 791 686 064</span>
                  </div>
                </div>
                <div className='col-md-4 col-lg-4'>
                  <div>
                    <i className='fas fa-envelope mr-2' />
                    <span>azouaoui.med@gmail.com</span>
                  </div>
                </div>
                <div className='col-md-4 col-lg-4'>
                  <div>
                    <i className='fas fa-map-marker-alt mr-2' />
                    <span>Algiers, Algeria</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12 col-lg-9 mr-auto ml-auto mb-4 animated fadeIn delay-12s'>
              <form method='POST'>
                <input type='hidden' name='form-name' value='contact' />
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <input
                      required
                      name='name'
                      type='text'
                      className='form-control custom-form form-control-lg'
                      placeholder='Name*'
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <input
                      required
                      name='subject'
                      type='text'
                      className='form-control custom-form form-control-lg'
                      placeholder='Subject*'
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <input
                    required
                    name='email'
                    type='email'
                    className='form-control custom-form form-control-lg'
                    placeholder='Email*'
                  />
                </div>

                <div className='form-group mb-5'>
                  <textarea
                    required
                    name='message'
                    className='form-control custom-form'
                    rows='8'
                    placeholder='Message*'
                  />
                </div>
                <div className='form-group mt-4'>
                  <button
                    type='submit'
                    className='btn btn-custom animated fadeInUp delay-14s'
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Contact;
