import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCE1WZob1CmBUBsl8mGwSXc0ZYj7Bj9Yck',
  authDomain: 'my-portfolio-600dc.firebaseapp.com',
  databaseURL: 'https://my-portfolio-600dc.firebaseio.com',
  projectId: 'my-portfolio-600dc',
  storageBucket: 'my-portfolio-600dc.appspot.com',
  messagingSenderId: '364919589100',
  appId: '1:364919589100:web:43ae6292f25bad65'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export { db };
