import React, { useState } from 'react';

import ImageLoading from '../../../UI/ImageLoading/ImageLoading';

const ProjectItem = props => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageLoadHandler = () => {
    setImageLoaded(true);
  };

  const errorHandler = error => {
    console.log('image not found');
  };
  return (
    <div
      className='card rounded-0 border-0 project-item animated fadeIn delay-02s'
      onClick={props.clicked}
    >
      <img
        src={props.src}
        className={`card-img rounded-0 ${imageLoaded ? 'imgLoaded' : ''}`}
        alt='...'
        onLoad={imageLoadHandler}
        onError={errorHandler}
        style={{ height: `${!imageLoaded ? '0' : 'auto'}` }}
      />
      <div className='card-img-overlay'>
        <span />
      </div>
      {!imageLoaded ? <ImageLoading /> : null}
    </div>
  );
};

export default ProjectItem;
