import React from 'react';

const ImageLoading = () => {
  return (
    <div
      className='image-loading bg-dark justify-content-center d-flex align-items-center'
      style={{ height: '200px' }}
    >
      Loading image...
    </div>
  );
};

export default ImageLoading;
