import React, { useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import path from 'path';

import { projectBaseURL } from '../../../config';
import SectionWrapper from '../../../HOC/SectionWrapper/SectionWrapper';
import ProjectItem from './ProjectItem/ProjectItem';
import ProjectDetails from './ProjectDetails/ProjetDetails';
import Loader from '../../UI/Loader/Loader';
import { withTracker } from '../../../withTracker';

const Portfolio = ({ projects, getProjects }) => {
  useEffect(() => {
    if (projects.length === 0) getProjects();
  });

  return (
    <SectionWrapper id='portfolio'>
      <div className='container'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title animated fadeInUp delay-02s'>
            Portfolio
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto animated fadeInUp delay-05s'>
            Checkout my recent work
          </h6>
        </div>
        <div className='section-content'>
          <div className='card-columns project-items'>
            {projects.length > 0 ? (
              projects.map((project, index) => {
                return (
                  <Link to={`/portfolio/${project.id}`} key={project.id}>
                    <ProjectItem
                      src={path.join(projectBaseURL, project.imageCover)}
                      id={project.name}
                    />
                  </Link>
                );
              })
            ) : (
              <Loader>Loading Projects ...</Loader>
            )}
          </div>
        </div>
      </div>

      {projects.length > 0 ? (
        <Route
          path='/portfolio/:projectId'
          render={(routeProps) => (
            <ProjectDetails {...routeProps} projects={projects} />
          )}
        />
      ) : null}
    </SectionWrapper>
  );
};

export default withTracker(Portfolio);
