import React from 'react';

import SectionWrapper from '../../../HOC/SectionWrapper/SectionWrapper';

const About = () => {
  return (
    <SectionWrapper id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 mr-auto ml-auto'>
            <h3 className='about-title text-uppercase mb-4 animated fadeInUp delay-02s'>
              About Me
            </h3>
            <p className='about-description mb-4 animated fadeIn delay-06s'>
              A professional Full-Stack Web Developer with over 6 years of
              hands-on experience developing both front and back end of complex,
              web-based applications, enjoys creating fully responsive, user
              friendly web experiences, and deliver highly accurate products
              that meet quality standards, and an excellent communication with
              Interpersonal skills and a self-motivated team player
            </p>
            <a
              href='https://docs.google.com/document/d/1k7vcpAnwPjg6DKy0lWWErV3rMt22V6GEuYoUywnN3Ko/edit'
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-custom mt-2 animated fadeInUp delay-1s'
            >
              <span>View Resume</span>
            </a>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default About;
