import React from 'react';

import Navbar from '../Navbar/Navbar';
const Header = () => {
  return (
    <header className='animated fadeInDown delay-1s'>
      <Navbar />
    </header>
  );
};

export default Header;
