import React from 'react';
import path from 'path';
import { useHistory, useParams, Redirect } from 'react-router';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { projectBaseURL } from '../../../../config';

const ProjetDetails = ({ projects }) => {
  const history = useHistory();
  const { projectId } = useParams();

  const project = projects.find(({ id }) => id === +projectId);

  const images = project.images.map(image => ({
    original: path.join(projectBaseURL, image)
  }));

  const handleCloseProjectDetail = () => {
    history.push('/portfolio');
  };

  return (
    <div className='project-details d-flex align-items-center animated fadeIn delay-01s'>
      <div className='close-project' onClick={handleCloseProjectDetail}>
        +
      </div>
      <div className='container innerSection'>
        {project ? (
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-4 animated fadeIn delay-03s'>
              <ImageGallery
                items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                autoPlay={true}
                showBullets={true}
                showThumbnails={false}
              />
            </div>
            <div className='col-md-12 col-lg-6 mb-4 text-left d-flex flex-column align-items-stretch'>
              <h3 className='project-title animated fadeIn delay-03s'>
                {project.name}
              </h3>
              <h4 className='project-subtitle animated fadeInUp delay-04s'>
                {project.title}
              </h4>
              <p className='project-description animated fadeInUp delay-05s'>
                {project.description}
              </p>
              <div className='tags animated fadeInUp delay-06s'>
                {project.tags.split(',').map((tag, index) => {
                  return <span key={index}>{tag}</span>;
                })}
              </div>
              {project.demoLink ? (
                <div className='flex-fill d-flex flex-column animated fadeInUp delay-06s'>
                  <div className='mt-auto'>
                    <hr />
                    <a
                      href={project.demoLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='btn btn-custom animated fadeIn delay-1s'
                    >
                      Live Preview
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <Redirect to='/portfolio' />
        )}
      </div>
    </div>
  );
};

export default ProjetDetails;
