import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import './scss/main.scss';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

export default App;
