import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { db } from '../../firebase';
import Header from '../UI/Header/Header';
import Home from '../sections/Home/Home';
import About from '../sections/About/About';
import Contact from '../sections/Contact/Contact';
import Skills from '../sections/Skills/Skills';
import Portfolio from '../sections/Portfolio/Portfolio';
import bgWrapper from '../../assets/img/bg3.jpg';
import { withTracker } from '../../withTracker';

const Layout = () => {
  const [projects, setProjects] = useState([]);

  const fetchProjects = () => {
    db.collection('projects')
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setProjects(data);
      });
  };

  return (
    <div className='wrapper' style={{ backgroundImage: `url(${bgWrapper})` }}>
      <Header />
      <main className=''>
        <Switch>
          <Route path='/about' component={withTracker(About)} />
          <Route path='/contact' component={withTracker(Contact)} />
          <Route path='/skills' component={withTracker(Skills)} />
          <Route
            path='/portfolio'
            render={(routeProps) => (
              <Portfolio
                {...routeProps}
                projects={projects}
                getProjects={fetchProjects}
              />
            )}
          />
          <Route path='/' component={withTracker(Home)} />
        </Switch>
      </main>
    </div>
  );
};

export default Layout;
